import React, { useEffect } from "react";
import { Media, ContentSection } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { Button } from "@ryerson/frontend.button";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import * as Utils from "../Utilities/Utilities";

type PolicyListItem = {
	title?: string;
	body: string[];
	linkLabel?: string;
	linkUrl?: string;
};

type PolicyList = {
	type: "ordered" | "unordered";
	listItems: PolicyListItem[];
};

export type PrivacyPolicyBodyContent = {
	downloadLink?: string | string[];
	downloadLabel?: string | string[];
	top: string[];
	list: PolicyList;
	bottom?: string[];
};

type PrivacyPolicyBodyProps = {
	content: PrivacyPolicyBodyContent;
};

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 18px;
`;

const Body = styled.div`
	border-radius: 2px;
	width: calc(100% - 300px);
	padding: 40px 37px 56px 37px;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
`;

const ButtonContainer = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 260px;
	margin-left: 40px;
`;

type CleanLink = {
	item: PolicyListItem;
	index: number;
};

const CleanAndLink: React.FC<CleanLink> = ({ item, index }) => {
	if (item.linkLabel && item.linkUrl && item.body[index].indexOf(item.linkLabel) !== -1) {
		let parts = item.body[index].split(item.linkLabel);
		return (
			<React.Fragment key={index}>
				<Typography
					css={css`
						// margin-bottom: 24px;
					`}
					variant="p"
					type="primary"
					size="md"
				>
					{parts.map((part: string, idx: number) => {
						return (
							<React.Fragment key={idx}>
								<Typography key={idx} variant="span" type="primary" size="md">
									{/* this will allow us to render the html tags in the string */}
									<span
										dangerouslySetInnerHTML={{
											__html: part,
										}}
									/>
								</Typography>
								{parts.length - 1 === idx ? (
									<></>
								) : (
									<Link to={item.linkUrl ?? "#"} type="primary" gatsby={false}>
										{item.linkLabel}
									</Link>
								)}
							</React.Fragment>
						);
					})}
				</Typography>
			</React.Fragment>
		);
	} else {
		return (
			<Typography
				css={css`
					// margin-bottom: 24px;
				`}
				variant="p"
				type="primary"
				size="md"
			>
				{<span dangerouslySetInnerHTML={{ __html: item.body[index] }} />}
			</Typography>
		);
	}
};

const PrivacyPolicyBody: React.FC<PrivacyPolicyBodyProps> = ({ content }) => {
	const { theme } = useTheme();

	//we need to add this style to the page to make the strong tag bold
	const weight = theme.typography.fonts.weights.bolder;
	const styleId = "privacy-policy-strong-style";
	const strongStyle = `
	  strong {
		font-weight: ${weight};
	  }
	`;
	Utils.appendStyle(strongStyle, styleId);

	useEffect(() => {
		return () => {
			Utils.removeStyle(styleId);
		};
	}, []);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px" hPadding="0px">
					<Body
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						{content.top.map((top: string, index: number) => {
							return (
								<Typography
									css={css`
										margin-bottom: 24px;
									`}
									key={index}
									variant="p"
									type="primary"
									size="md"
								>
									{
										//this will allow us to render the html tags in the string
										<span
											dangerouslySetInnerHTML={{
												__html: top,
											}}
										/>
									}
								</Typography>
							);
						})}
						{content.list.listItems.map((item: PolicyListItem, index: number) => {
							return (
								<React.Fragment key={index}>
									<Typography
										css={css`
											margin-bottom: 24px;
											margin-top: 16px;
										`}
										variant="h4"
										type="primary"
									>
										{content.list.type === "ordered"
											? index + 1 + ". " + item.title
											: item.title}
									</Typography>
									{item.body.map((body: string, idx: number) => {
										return <CleanAndLink item={item} index={idx} />;
									})}
								</React.Fragment>
							);
						})}

						{!!content.bottom?.length && <hr />}

						{content.bottom?.map((item: string, index: number) => {
							return (
								<Typography
									css={css`
										margin-bottom: 24px;
									`}
									key={index}
									variant="p"
									type="primary"
									size="md"
								>
									{
										// this will allow us to render the html tags in the string
										<span
											dangerouslySetInnerHTML={{
												__html: item,
											}}
										/>
									}
								</Typography>
							);
						})}
					</Body>

					<ButtonContainer>
						{content.downloadLink &&
							content.downloadLabel &&
							(Array.isArray(content.downloadLink) &&
							Array.isArray(content.downloadLabel) ? (
								content.downloadLink.map((link: string, index: number) => {
									return (
										<React.Fragment key={index}>
											<Link to={link} gatsby={false} key={index}>
												<Button
													onClick={() => {}}
													leftIcon="file-pdf"
													shape="rounded"
													align="left"
													fullwidth={true}
													type="secondary"
													label={
														content.downloadLabel &&
														content.downloadLabel[index]
															? content.downloadLabel[index]
															: ""
													}
												/>
											</Link>
											<Spacer />
										</React.Fragment>
									);
								})
							) : (
								<Link
									gatsby={false}
									to={
										!Array.isArray(content.downloadLink)
											? content.downloadLink
											: ""
									}
								>
									<Button
										onClick={() => {}}
										leftIcon="file-pdf"
										shape="rounded"
										fullwidth={true}
										type="secondary"
										label={
											!Array.isArray(content.downloadLabel)
												? content.downloadLabel
												: ""
										}
									/>
								</Link>
							))}
					</ButtonContainer>
					<Spacer
						css={css`
							height: 90px;
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="20px" hPadding="0px">
					{content.downloadLink &&
						content.downloadLabel &&
						(Array.isArray(content.downloadLink) &&
						Array.isArray(content.downloadLabel) ? (
							content.downloadLink.map((link: string, index: number) => {
								return (
									<React.Fragment key={index}>
										<Link gatsby={false} to={link} key={index}>
											<Button
												onClick={() => {}}
												leftIcon="file-pdf"
												shape="rounded"
												align="left"
												fullwidth={true}
												type="secondary"
												label={
													content.downloadLabel &&
													content.downloadLabel[index]
														? content.downloadLabel[index]
														: ""
												}
											/>
										</Link>
										<Spacer />
									</React.Fragment>
								);
							})
						) : (
							<Link
								gatsby={false}
								to={
									!Array.isArray(content.downloadLink) ? content.downloadLink : ""
								}
							>
								<Button
									onClick={() => {}}
									leftIcon="file-pdf"
									shape="rounded"
									fullwidth={true}
									type="secondary"
									label={
										!Array.isArray(content.downloadLabel)
											? content.downloadLabel
											: ""
									}
								/>
							</Link>
						))}
					{content.top.map((top: string, index: number) => {
						return (
							<Typography
								css={css`
									margin-bottom: 10px;
								`}
								key={index}
								variant="p"
								type="primary"
								size="md"
							>
								<span
									dangerouslySetInnerHTML={{
										__html: top,
									}}
								/>
							</Typography>
						);
					})}

					{content.list.listItems.map((item: PolicyListItem, index: number) => {
						return (
							<React.Fragment key={index}>
								<Typography
									css={css`
										margin-bottom: 10px;
										margin-top: 14px;
									`}
									variant="h4"
									type="primary"
								>
									{content.list.type === "ordered"
										? index + 1 + ". " + item.title
										: item.title}
								</Typography>
								{item.body.map((body: string, idx: number) => {
									return <CleanAndLink item={item} index={idx} />;
								})}
							</React.Fragment>
						);
					})}

					{!!content.bottom?.length && <hr />}

					{content.bottom?.map((item: string, index: number) => {
						return (
							<Typography
								css={css`
									margin-bottom: 24px;
								`}
								key={index}
								variant="p"
								type="primary"
								size="md"
							>
								{
									//this will allow us to render the html tags in the string
									<span
										dangerouslySetInnerHTML={{
											__html: item,
										}}
									/>
								}
							</Typography>
						);
					})}
				</ContentSection>
			</Media>
		</>
	);
};

export default PrivacyPolicyBody;
